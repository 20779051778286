<template>
  <validation-observer
    ref="conductCriteriaSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="conductCriteriaSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm tiêu chí' : 'Cập nhật tiêu chí'"
      @show="onShow"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group
          label="Tên tiêu chí"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            name="Tên tiêu chí"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetConductCriteria.name"
              name="name"
              placeholder="Nhập tên tiêu chí"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Điểm tối đa"
          label-for="maxScore"
        >
          <validation-provider
            #default="{ errors }"
            name="Điểm tối đa"
            rules="required|numeric|max-value:100"
          >
            <b-form-input
              id="maxScore"
              v-model="targetConductCriteria.maxScore"
              name="maxScore"
              placeholder="Nhập điểm tối đa"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Trạng thái"
          label-for="status"
        >
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetConductCriteria.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Thứ tự"
          label-for="orderNo"
        >
          <validation-provider
            #default="{ errors }"
            name="Thứ tự"
            rules="required|numeric"
          >
            <b-form-input
              id="orderNo"
              v-model="targetConductCriteria.orderNo"
              name="orderNo"
              placeholder="Nhập thứ tự"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('conductCriteriaSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { maxValue, required, numeric } from '@core/utils/validations/validations'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { STATUSES } from '@/const/status'

export default {
  name: 'ConductCriteriaSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    conductId: {
      type: Number,
      default: null,
    },
    conductCriteria: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetConductCriteria: {
        name: '',
        maxScore: null,
        conductId: null,
        status: 1,
        orderNo: 1,
      },
      required,
      numeric,
      maxValue,
    }
  },
  computed: {
    statusOptions() {
      return STATUSES
    },
    menuOptions() {
      return this.menus.map(menu => ({ value: menu.id, label: menu.name }))
    },
    isCreated() {
      return !this.conductCriteria
    },
  },
  methods: {
    ...mapActions({
      createConductCriteria: 'conductCriteria/createConductCriteria',
      updateConductCriteria: 'conductCriteria/updateConductCriteria',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.conductCriteria) {
        this.targetConductCriteria = { ...this.conductCriteria }
      }
      this.targetConductCriteria.conductId = this.conductId
    },
    onHide() {
      this.$refs
        .conductCriteriaSaveFormRef
        .reset()
      this.targetConductCriteria = {
        name: '',
        code: '',
        menuId: null,
        route: '',
        title: '',
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .conductCriteriaSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createConductCriteria(this.targetConductCriteria)
            : await this.updateConductCriteria(this.targetConductCriteria)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('conductCriteriaSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
